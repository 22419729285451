<template>
  <div>
    <van-cell-group>
      <div class="top">
        <div
          style="display: flex; align-items: center; padding: 0px 15px"
          class="van-hairline--bottom"
        >
          <div style="width: 100px">{{ $t("邮编") }}</div>
          <van-field
            size="large"
            :placeholder="$t('请输入邮编')"
            v-model="postCode"
            maxlength="6"
            type="number"
          />
        </div>

        <div
          style="display: flex; align-items: center; padding: 0px 15px"
          class="van-hairline--bottom"
        >
          <div style="width: 100px">{{ $t("货物类别") }}</div>
          <van-radio-group
            v-model="cargoType"
            direction="horizontal"
            style="padding: 15px"
          >
            <van-radio name="A" checked-color="#40c057">A</van-radio>
            <van-radio name="B" checked-color="#40c057">B</van-radio>
            <van-radio name="C" checked-color="#40c057">C</van-radio>
          </van-radio-group>
        </div>

        <div
          style="display: flex; align-items: center; padding: 0px 15px"
          class="van-hairline--bottom"
        >
          <div style="width: 100px">{{ $t("运输方式") }}</div>
          <van-radio-group
            v-model="expressWay"
            direction="horizontal"
            style="padding: 15px"
          >
            <van-radio name="1" checked-color="#40c057">{{
              $t("陆运")
            }}</van-radio>
            <van-radio name="2" checked-color="#40c057">{{
              $t("海运")
            }}</van-radio>
          </van-radio-group>
        </div>

        <div
          style="display: flex; align-items: center; padding: 0px 15px"
          class="van-hairline--bottom"
        >
          <div style="width: 100px">{{ $t("重量") + "(KG)" }}</div>
          <van-field
            size="large"
            :placeholder="$t('请输入重量')"
            v-model="weight"
            maxlength="8"
            type="number"
          />
        </div>
        <div style="padding: 5px 15px 0px; color: #999; font-size: 0.7rem">
          <span v-if="woodenframe" style="color: #f00">*</span>
          {{ woodenframe ? $t("必填") : $t("选填") }}
        </div>
        <div style="display: flex" class="van-hairline--bottom">
          <div>
            <van-field
              v-model="length"
              :placeholder="$t('长') + '(cm)'"
              maxlength="8"
              type="digit"
            />
          </div>
          <div>
            <van-field
              :placeholder="$t('宽') + '(cm)'"
              type="digit"
              maxlength="8"
              v-model="width"
            />
          </div>
          <div>
            <van-field
              :placeholder="$t('高') + '(cm)'"
              v-model="height"
              maxlength="8"
              type="digit"
            />
          </div>
        </div>
      </div>
      <div style="padding: 10px; display: flex">
        <van-button block round style="flex: 1" @click="toPriceLabel">{{
          $t("查看价格表")
        }}</van-button>
        <van-button
          block
          round
          type="primary"
          :loading="submitLoading"
          :disabled="submitLoading"
          style="flex: 1; margin-left: 15px"
          @click="calculate"
          >{{ $t("计算") }}</van-button
        >
      </div>

      <div v-if="result.fee" class="result">
        <div style="font-size: 18px; padding: 15px">{{ $t("预估费用") }}：</div>
        <van-cell
          :title="$t('计费方式')"
          :value="$t(convertBillingMethod(result.billing_method))"
        />
        <van-cell
          v-if="result && result.value"
          :title="$t('体积')"
          :value="result.value + 'm³'"
        />
        <van-cell :title="$t('转运费')" :value="result.fee + $t('铢')" />
        <van-cell
          v-if="localResult && localResult.fee"
          :title="$t('本地运费')"
          :value="localResult.fee + $t('铢')"
        />
        <van-cell v-if="total" :title="$t('Total')" :value="total + $t('铢')" />
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Toast,
  Field,
  RadioGroup,
  Radio,
  Button,
  Checkbox,
} from "vant";
import { localRouteFeeEstimation, woodenframeEstimation } from "@/service/api";
import { convertBillingMethod } from "@/utils/dict";
import { openWebPageLocale } from "@/utils/pages";
import { defaultAddress, estimation, feeEstimation } from "./service";
import { getShopifyCustomer } from "@/utils/token";
import { COMPANY } from "@/utils/constants";
import { getUserInfo } from "@/utils/token";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      postCode: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      type: "inter",
      cargoType: "A",
      woodenframe: false,
      expressWay: "1",
      fee: "",
      result: {},
      woodenframeResult: null,
      localResult: {},
      submitLoading: false,
      total: 0,
    };
  },
  methods: {
    typeChange() {
      this.fee = null;
    },
    convertBillingMethod(value) {
      return convertBillingMethod(value);
    },
    toPriceLabel() {
      openWebPageLocale("helpcenter", "shipping-policy");
    },
    doReset() {
      this.length = null;
      this.width = null;
      this.height = null;
      this.weight = null;
      this.cargoType = "A";
      this.woodenframe = false;
      this.result = {};
    },
    async defaultAddress() {
      const shopifyCustomer = getShopifyCustomer();
      if (!shopifyCustomer) {
        Toast.fail("customer access token invalid");
        return;
      }

      const { shopifyCustAccessToken } = shopifyCustomer;

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await defaultAddress(shopifyCustAccessToken);
      Toast.clear();
      const { defaultAddress: address } = res.data.customer;
      if (address) {
        this.address = address;
      }
    },
    calculate() {
      if (!this.postCode) {
        Toast.fail(this.$t("请输入邮编"));
        return;
      }

      if (!this.weight) {
        Toast.fail(this.$t("请输入重量"));
        return;
      }

      if (this.woodenframe) {
        if (!this.length) {
          Toast.fail(this.$t("请输入长度"));
          return;
        }

        if (!this.width) {
          Toast.fail(this.$t("请输入宽度"));
          return;
        }

        if (!this.height) {
          Toast.fail(this.$t("请输入高度"));
          return;
        }
      }

      let payload = {
        weight: this.weight,
        postCode: this.postCode,
      };

      if (this.length) {
        payload = { ...payload, length: this.length };
      }

      if (this.width) {
        payload = { ...payload, width: this.width };
      }

      if (this.height) {
        payload = { ...payload, height: this.height };
      }

      const userInfo = getUserInfo();
      payload = {
        ...payload,
        expressWay: this.expressWay,
        cargoType: this.cargoType,
        company: COMPANY,
        vip: userInfo && userInfo.user.vip,
      };
      this.feeEstimation(payload);
      this.estimation(payload);
    },

    async estimation(payload) {
      const res = await estimation(payload);
      if (res.success) {
        // this.fee = res.data.fee;
        this.localResult = res.data;
      } else Toast.fail(res.message);

      this.calculateTotal();
      this.submitLoading = false;
    },
    calculateTotal() {
      const feeShip = this.result && this.result.fee;
      const feeLocal = this.localResult && this.localResult.fee;

      if (feeShip && feeLocal) {
        this.total = this.NumberAdd(feeShip, feeLocal);
      } else this.total = 0;
    },
    async woodenFrameEstimation(payload) {
      this.submitLoading = true;
      const res = await woodenframeEstimation(payload);

      if (res.success) {
        // this.fee = res.data.fee;
        this.woodenframeResult = res.data;
      } else Toast.fail(res.message);

      this.submitLoading = false;
    },

    async feeEstimation(payload) {
      this.submitLoading = true;
      const res = await feeEstimation(payload);

      if (res.success) {
        // this.fee = res.data.fee;
        this.result = res.data;
      } else Toast.fail(res.message);

      this.calculateTotal();
      this.submitLoading = false;
    },

    async localRouteFeeEstimation(payload) {
      this.submitLoading = true;
      const res = await localRouteFeeEstimation(payload);
      if (res.success) {
        // this.fee = res.data.fee;
        this.localResult = res.data;
      } else Toast.fail(res.message);

      this.submitLoading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.contactor__name-wrapper {
  display: flex;
  align-items: center;

  div {
    flex: 1;
  }
}
:deep(.van-field__label) {
  width: auto;
  margin-right: 31px;
}

.top {
  .van-cell__value {
    min-width: 75%;
  }
}
.van-cell {
  font-size: 1rem;
  align-items: center;
}

.van-radio {
  padding: 5px 0px;
}
</style>