import {
    query,
} from "@/utils/apollo-utils.js"
import axios from '@/utils/request';
import {
    defaultAddress as defaultAddressGql,
} from "./gqls.js"

export async function defaultAddress(customerAccessToken) {
    return query(defaultAddressGql, {
        customerAccessToken
    })
}

export async function estimation(payload) {
    return axios.get('cargo/v2/localRouteFee/estimation', payload);
}

export async function feeEstimation(payload) {
    return axios.get('cargo/v2/fee/estimation', payload)
}